$text         : #313437;
$primary      : #3f3f3f;
$secondary    : #bc3e3e;

// lighten() and darken() might not work very well with certain colors,
// allow the option to enter these manually.
$secondary--light : lighten($secondary, 5%);
$secondary--dark  : darken($secondary, 5%);

$gray         : #abb7b7;
$lightGray    : #dadfe1;
$paleGray     : #ececec;
$lightBlue    : #446CB3;
$navyBlue     : #1f3a93;
$error        : #D91E18;
$message      : #89C4F4;
$info         : $lightBlue;
$success      : #049372;
$warning      : #E87E04;
$link         : #4b77be;
$visited      : #4d13d1;
$gold         : #A28E5B;
$darkPurple   : #200221;
$lightPurple  : #39043B;
$pink         : #BD86BF;
$dcpro        : #313e4f;
$ceedaLight   : #bee1db;
$ceeda        : #006C43;

$breakpoints  : (xsmall: 480px, small: 768px, medium: 1087px, large: 1280px);
$sans-serif   : 'museo-sans', Helvetica, sans-serif;
$slab         : 'museo-slab', serif;
$light        : 100;
$normal       : 300;
$bold         : 500;
$extrabold    : 700;
