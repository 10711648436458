.marketoform {

    width: 100% !important;

    .mktoAsterix {
        display: none;
    }

    .mktoRequiredField {
        .mktoAsterix {
            display: inline;
            color: $error;
        }
    }

    .mktoFormRow {
        display: flex;
    }

    .mktoButtonRow {
        margin-top: 0.5rem;
    }

    .mktoFormCol {
        flex-grow: 1;
        flex-basis: 100%;
        box-sizing: border-box;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        margin-bottom: 0.75rem;
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea {
        @extend .form__input;
    }

    select {
        @extend .form__select;
    }

    .mktoLabel {
        @extend .form__label;
    }

    .mktoRadioList,
    .mktoCheckboxList {
        label {
            margin-left: 0.5rem;

            &:not(:last-child):after {
                content: "";
                display: block;
                width: 0;
                height: 0;
            }
        }
    }

    .mktoHtmlText {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    button[type="submit"] {
        @extend .button;
    }

    .mktoFieldWrap {
        position: relative;
    }

    .mktoError {
        color: white;
        background: $error;
        font-size: 90%;
        padding: 0.25rem;
    }

    fieldset {
        padding: 0.25rem 0.5rem;

        input{
            margin-top: 0.75rem;
        }
    }

    legend {
        padding: 0.25rem 0.5rem;
        font-weight: $bold;
        font-size: 1.25rem;
    }
}

.marketoform--sidebar {
    button[type="submit"] {
        @extend .button--block-narrow;
    }
}

.marketoform--light {
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea,
    select {
        background-color: white;
    }
}

