.ticket-list {
    display: flex;
    flex-wrap: wrap;
    border-bottom: none;
    flex-grow: 1;
    align-items: unset;
}

.ticket {
    display: flex;
    box-sizing: border-box;
    padding: 0.25rem 0.5rem;
    border: 1px solid $paleGray;
    flex-grow: 1;
    flex-basis: 0;
    margin: 0.25rem;

    &.is-active {
        border-width: 1px;
        border-color: $secondary;
    }
}

.ticket__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    text-align: center;

    .ticket__title {
        font-size: 1.5rem;
        font-weight: normal;
    }

    .ticket__price {
        font-size: 1.75rem;
        font-weight: $light;
        margin: 0.5rem 0;
        color: $secondary;
    }

    .ticket__subtitle {
        font-weight: $light;
        margin-bottom: 0.5rem;
    }
}

.ticket-criteria {
    margin: 0 0.25rem;
    ol ol {
        list-style-type: lower-alpha;
    }
}
