.sf-unordered-list {
    @extend .plain-list;

    li {
        display: block;
        padding: 0.5rem;
        line-height: 150%;
        box-sizing: border-box;
        background-color: $paleGray;
        border-left: 3px solid $gray;
        &:not(:last-of-type) {
            margin-bottom: 0.5rem;
        }
    }
}

.sf-unordered-list--award {
    li {
        font-family: $slab;
        border-left: 3px solid $gold;
    }
}

.sf-unordered-list--plain {
    li {
        background-color: none;
        border-left: 3px solid $primary;
    }
}