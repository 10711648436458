/*
    Note: this component uses `<elem class="block block--mod">...</elem>` syntax.
*/

.stack {
    .card {
        margin-bottom: 0;
    }

    .line-block {
        margin-bottom: 0;
    }

    margin-bottom: 1rem;
}

.stack--gray {
    .stack__secondary,
    .stack__more {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }

    background: $paleGray;

    .card {
        .card__body {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
    }
}

.stack__secondary {
    .stack__primary + & {
        padding-top: 0;
    }
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;    
}

.stack__more {
    text-align: right;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
