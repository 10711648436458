.list {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;

    > li {
        list-style-position: inside;
        list-style-type: disc;
    }
}

.inline-list {
    @include inline-list();
    > li img {
        vertical-align: middle;
    }
}

.inline-list__title {
    display: inline;

    & + .inline-list {
        display: inline-block;
        vertical-align: middle;
    }
}

.inline-list--right {
    @extend .inline-list;
    display: inline;
    float: right;
}

.list--four-col {
    @extend .list;

    @include media(">xsmall") {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        flex-flow: row-wrap;
        align-content: flex-end;

        li {
            width: calc(99.9% * 1/4 - (30px - 30px * 1/4));
            &:nth-child(1n) {
                margin-right: 30px;
            }
            &:nth-child(4n) {
                margin-right: 0;
            }
        }
    }
}

.plain-list {
    @extend .list;
    margin: 0;

    li {
        list-style-type: none;
    }
}

.plain-list--links {
    @extend .plain-list;
    @include media("<=small") {
        li {
            a {
                display: inline-block;
                padding-top: 0.25rem;
                padding-bottom: 0.25rem;
                padding-right: 0.5rem;
                margin-top: 0.25rem;
                margin-bottom: 0.25rem;
            }
        }
    }
}

.plain-list--counted {
    @extend .plain-list;
    counter-reset: plain-list-counted;

    li {
        counter-increment: plain-list-counted;
        position: relative;
        padding-right: 2rem;

        &:before {
            content: counter(plain-list-counted);
            position: absolute;
            top: 0;
            right: 0;
            font-size: 1.25rem;
            background: $secondary;
            width: 1.5rem;
            text-align: center;
            color: white;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
    }
}

.plain-list--sub {
    @extend .plain-list;
    margin-left: 0.5rem;
    font-size: 0.9rem;

    .svg-inline--fa {
        color: $secondary;
        vertical-align: baseline;
    }
}

.plain-list--box {
    @extend .plain-list;
    li {
        box-sizing: border-box;
        margin-bottom: 1rem;
        background: $paleGray;
        border-bottom: 1px solid $gray;
        padding: 0.5rem;

        > * {
            margin-bottom: 0;
        }
    }
}

.fenced-list {
    @include inline-list(0);
    > li {
        &:not(:last-of-type):after {
            padding-left: 0.25rem;
            padding-right: 0.25rem;
            content: '//';
            color: $secondary--light;
        }
    }
}

.fenced-list--white {
    @extend .fenced-list;
    > li {
        &:not(:last-of-type):after {
            color: white;
        }
    }
}

.list__item--separated {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    &:not(:last-of-type) {
        border-bottom: 1px solid $gray;
    }
}

.list--spaced {
    @extend .list;
    > li {
        margin: 0.25rem 0;
    }
}

.list--two-col {
    @include media(">xsmall") {
        column-count: 2;
        column-gap: 2rem;
    }
}
