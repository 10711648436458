.link {
    @extend a;
}

.nav-link {
    &:link,
    &:active,
    &:visited {
        color: $text;
        text-decoration: none;
    }
    &:hover {
        color: $navyBlue;
    }
}

.nav-link--light {
    font-weight: $normal;
    &:link,
    &:active,
    &:visited {
        color: white;
        text-decoration: none;
    }
}

.headline-link {
    font-weight: $extrabold;
    &:link,
    &:active,
    &:visited {
        color: $text;
        text-decoration: none;
    }
    &:visited {
        color: lighten($text, 20%);
    }
    &:hover {
        color: $navyBlue;
        text-decoration: underline;
    }
}

// note: parent must have `position: relative;`
.block-link {
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
    }
}
