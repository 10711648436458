// a large panel with a background image, optional heading, and optional calls to action
// the image is defined inline using background: url(path-to-image);

.hero {
    display: flex;
    flex-direction: column;
    position: relative;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    min-height: 350px;
}

.hero--large {
    min-height: 450px;
}

.hero__heading {
    padding: 0.5rem;
    color: white;
    background: rgba(0, 0, 0, 0.75);
    font-family: $slab;
    font-size: 2rem;
    font-weight: $light;
    order: 99;
    margin-top: auto;
    margin-bottom: 0;
}

.hero__cta {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: flex-end;
    width: 30%;
    margin-left: auto;
    margin-right: 1rem;

    @include media("<small") {
        justify-content: center;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}

.hero__cta-list {
    @extend .plain-list;
    box-sizing: border-box;
    width: 100%;
    padding: 0.5rem;
}

.hero__cta-item {
    &+& {
        margin-top: 0.5rem;
    }
}

.hero--compact {
    min-height: 200px;

    .hero__heading {
        font-size: 1.5rem;
    }
}

.hero__body {
    padding: 0.5rem;
    border-left: 1px solid $gray;
    border-right: 1px solid $gray;
    border-bottom: 1px solid $gray;
}