/*
    Note: this component uses `<elem class="block block--mod">...</elem>` syntax.
*/

.label {
    font-family: $sans-serif;
    display: inline-block;
    padding: 0.25em 0.25em 0.25em 0.5em;
    margin-right: 0.25em;
    font-size: 75%;
    white-space: nowrap;
    vertical-align: middle;
    background: $secondary--light;
    color: white;
    position: relative;
    z-index: 1;

    &:after {
        position: absolute;
        content: '';
        z-index: -1;
        right: -3%;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: inherit;
        transform: skewX(-10deg);
        transform-origin: 100%;
    }
}

.label--blue {
    background: $lightBlue;
}

.label--navy-blue {
    background: $navyBlue;
}

.label--gold {
    background: $gold;
}

.label--light {
    background: $lightGray;
    color: $primary;
}

.label--large {
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    font-size: 100%;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
}

.label--block {
    display: block;
}
