.inline-description {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;

    dt {
        display: inline;
        &:after {
            content: ":";
        }
    }
    dd {
        display: inline;
        margin-left: 0;

        + dd {
            &:before {
                content: "| ";
            }
        }
    }

    dd + dt {
        &:before {
            content: "// ";
            color: $gray;
        }
    }
}

.inline-description--bold {
    dt {
        font-weight: $bold;
    }
}

.inline-description--inline {
    @extend .inline-description;
    display: inline;
}

.plain-description {
    dt {
        display: block;
        font-weight: $bold;
        &:after {
            content: ":";
        }

        &:not(:first-child) {
            margin-top: 0.5rem;
        }
    }
    dd {
        display: block;
        margin-left: 0;
    }
}
