.sf-document {
    background: $paleGray;
    display: inline-block;
    float: left;
    margin-right: 1rem;
}

.sf-document__title {
    display: block;
    padding: 1rem 0.75rem;

    .svg-inline--fa {
        vertical-align: middle;
    }
}

.sf-document--block {
    float: none;
    margin-right: 0;
    display: block;

    .sf-document--audio {
        width: 100%;
    }
}

.sf-document--plain {
    background: none;
}
