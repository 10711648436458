.announcement {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    padding: 0;
    align-items: center;
    background-color: $paleGray;
    color: $primary;
    border-bottom: 3px solid $secondary;
    justify-content: space-between;
}

.announcement__message {
    margin: 0 1rem 0 0;
    padding: 0.5rem;
    flex-grow: 1;
}

.announcement__cta {
    display: block;

    &:link,
    &:active,
    &:visited {
        color: $primary;
        text-decoration: none;
        font-weight: $bold;
    }

    &:hover {
        text-decoration: underline;
        color: $navyBlue;
    }
}

.announcement__dismiss {
    margin-left: auto;
    margin-right: 0.5rem;
    cursor: pointer;
    color: $secondary;
}

.announcement--hidden {
    display: none;
}