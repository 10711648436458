.tag-container {
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.tag {
    display: inline-block;
    font-weight: $bold;
    padding: 0.25rem 0.5rem;
    border: 1px solid $primary;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;

    &.active {
        .fa-check {
            opacity: 1;
            transition: 0.1s opacity linear;
        }
        .fa-times {
            opacity: 0;
            transition: 0.1s opacity linear;
        }
        &:hover {
            .fa-check {
                opacity: 0;
            }
            .fa-times {
                opacity: 1;
            }
        }
    }
}
