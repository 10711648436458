.message {
    display: flex;
    box-sizing: border-box;
    margin-bottom: 1rem;
    background: $paleGray;
    color: $text;
}

.message__icon {
    padding: 1rem;
    line-height: 100%;
    display: block;
    text-align: center;
    background: none;
    color: white;
}

.message__body {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-self: center;
    flex-grow: 1;

    p {
        margin: 0;
    }
}

.message__title {
    font-family: $slab;
    font-size: 1.5rem;
}

.message__cta {
    align-self: flex-end;
}

.message--info {
    @extend .message;

    .message__icon {
        background: $info;
    }
}

.message--success {
    @extend .message;

    .message__icon {
        background: $success;
    }
}

.message--warning {
    @extend .message;

    .message__icon {
        background: $warning;
    }
}

.message--error {
    @extend .message;

    .message__icon {
        background: $error;
    }
}