.video-hero {
    position: relative;
    width: 100%;
    height: 500px;
}

.video-hero__video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
}

.video-hero__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: radial-gradient(at center, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2));
}

.video-hero__body {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .brand-header:before {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 5%, rgba(0, 0, 0, 0));
    }
}