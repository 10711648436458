.brand-header {
    position: relative;
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 170px;
        z-index: 98;
        content: "";
        background: url("../img/event-header.svg");
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 140px;
    color: white;

    .icon-separator {
        color: $secondary;
    }

    @include media("<=small") {
        padding: 1rem 0.5rem;
        background: $primary;
        height: initial;

        .brand-header__body {
            padding: 0;
        }

        &:before {
            display: none;
        }
    }
}

.brand-header--awards {
    &:before {
        background: url("../img/award-header.svg");
    }
}

.brand-header--training {
    &:before {
        background: url("../img/training-header.svg");
    }

    .brand-header__title {
        h1 {
            font-weight: $normal;
        }
    }
}

.brand-header--compact {
    margin-bottom: calc(20px + 1rem);

    @include media("<=small") {
        margin-bottom: 0.5rem;

        .brand-header__cta {
            flex-basis: 100%;
            box-sizing: border-box;
            margin-left: 10vw;
            margin-right: 10vw;
            margin-top: 0.75rem;
        }
    }
}

.brand-header__body {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    position: relative;
    z-index: 99;
    padding: 0 1.5rem;
    box-sizing: border-box;
}

.brand-header__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 2rem;
    font-family: $slab;
    margin: 0;

    h1 {
        font-size: 2rem;
        margin: 0;
        font-weight: $bold;
        margin-left: 0.75rem;
    }

    .brand-header__link {
        display: flex;
        align-items: center;
    }

    .icon {
        font-size: 1.25rem;
        color: $secondary;
        margin-left: 0.75rem;
    }
}

.brand-header__countdown {
    font-family: $slab;
    display: flex;
    align-items: center;

    .countdown-icon {
        font-size: 3rem;
        margin-right: 0.5rem;
    }
    .countdown-content {
        .countdown-figure {
            font-size: 2.5rem;
            display: block;
            line-height: 80%;
            text-align: center;
        }
        .countdown-label {
            font-size: 1rem;
            display: block;
            text-align: center;
        }
    }
}

.brand-header__info {
    margin-top: 0.5rem;
    flex-basis: 100%;
    font-size: 1.2rem;
}

.brand-header__partners {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.hero + .brand-header__strapline {
    margin-top: 1rem;
}

.brand-header__hashtag {
    font-size: 1.75rem;
    font-family: $slab;
    font-weight: $normal;
    margin-right: auto;
    margin-left: 0.75rem;
}

.brand-header__intro {
    font-size: 1.25rem;
}
