.navigation {
    /* 
        Main navigation bar wrapper.

        Applies to <ul>s, making items in the nav spread horizontally.
    */
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;

    --bg-color: #{$primary};
    --hover-color: #{lighten($primary, 5%)};
    --text-color: white;
    --dropdown-bg-color: #{lighten($primary, 5%)};
    --dropdown-hover-color: #{lighten($primary, 10%)};
    --dropdown-text-color: white;
}

.navigation--secondary {
    --bg-color: #{$lightGray};
    --hover-color: #{lighten($lightGray, 5%)};
    --text-color: black;
    --dropdown-bg-color: #{lighten($lightGray, 5%)};
    --dropdown-hover-color: #{lighten($lightGray, 10%)};
    --dropdown-text-color: black;
    --dropdown-border-color: $gray;

    &:not(:last-child) {
        flex: 1;
    }

}

.navigation--spaced {
    justify-content: space-between;
}

.navigation--prev-next {
    .navigation__item--prev {
        margin-right: auto;
    }

    .navigation__item--next {
        margin-left: auto;
    }
}

.navigation__item {
    /* 
        Basic item inside a navigation bar.

        Typically applied to <li> inside .navigation.

        Applies styles to buttons and anchors inside the <li>
    */
    position: relative;

    >a,
    >a:active,
    >a:visited,
    >button {
        display: flex;
        box-sizing: border-box;
        height: 100%;
        align-items: center;
        padding: 0.5rem;
        background-color: transparent;
        border: 0;
        cursor: pointer;
        color: var(--text-color);
        text-decoration: none;

        &.active {
            font-weight: bold;
            background: var(--hover-color);
        }

        &:hover {
            color: var(--text-color);
        }

        .icon {
            margin-right: 0.25rem;
        }
    }

    &:hover {
        background: var(--hover-color);
    }
}

.navigation__item--primary {

    /* 
        Variant of .navigation__item which is used for the main
        menu at the top of every page.

        Sets a chevron before each item.
    */
    >a,
    >button {
        &:before {
            content: ">";
            margin-right: 0.5rem;
            color: #ccc;
        }
    }
}

.navigation__item--group-header > span {
    font-weight: bold;
    padding: 0.5rem;
    color: var(--text-color);
    display: block;
    background: color-mix(in sRGB, var(--bg-color) 70%, black);
}

.navigation__item--group-item {
    > a, > a:visited, > a:active {
        background: color-mix(in sRGB, var(--bg-color) 95%, black);
        border-left: 6px solid color-mix(in sRGB, var(--bg-color) 70%, black);
    }

    >a:hover {
        background: var(--hover-color);
    }
}

.navigation--primary {
    .navigation__dropdown {
        >li {
            &:not(:last-child) {
                border-bottom: 1px solid $gray;
            }
        }
    }
}

.navigation--primary>.navigation__item--primary {
    // extra styles for the first three dropdowns

    box-sizing: border-box;

    &>a,
    &>button {
        padding: 0 1rem;

    }

    &:nth-of-type(2) {
        --bg-color: #{$paleGray};
        --dropdown-bg-color: #{$paleGray};

        background: var(--bg-color);

        >button {
            color: $primary;

            &:before {
                color: $primary;
            }
        }

        .navigation__dropdown {
            background: var(--dropdown-bg-color);

            .navigation__item--primary {
                &>a,
                &>button {
                    color: $primary;

                    &:before {
                        color: $secondary;
                    }

                    &:hover {
                        color: white;
                    }
                }
            }
        }

        &:hover {
            background: var(--dropdown-bg-color);

            >button {
                color: $primary;

                &:before {
                    color: $primary;
                }
            }
        }
    }

    &:nth-of-type(3) {
        --bg-color: #{lighten($primary, 20%)};
        --dropdown-bg-color: #{lighten($primary, 20%)};

        background: var(--bg-color);

        .navigation__dropdown {
            background: var(--dropdown-bg-color);
        }
    }
}

.navigation__item--sub {
    /* 
        Navigation item variant for items within a primary menu dropdown
    */
    border-bottom: var(--dropdown-hover-color);

    &:last-child {
        border-bottom: 0;
    }

    &:hover {
        background: var(--dropdown-hover-color);
    }
}

.navigation__dropdown {
    /* 
        Wrapper for a dropdown menu that appears when
        hovering over a menu item.

        Usually applied to a <ul> element.

        The dropdown should exist inside the <li> of the parent menu item:
        ```
            <li class="navigation__item">
                <button>Menu Item</button>
                <ul class="navigation__dropdown">
                    <li><a>Subitem</a></li>
                </ul> 
            </li>
        ```
    */
    position: absolute;
    top: 100%;
    background: var(--dropdown-bg-color);
    width: 20rem;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s linear;
    width: 300px;
    z-index: 9999;
    list-style: none;
    margin: 0;
    padding: 0;

    >li {
        width: 100%;
    }

    >li a {
        color: var(--dropdown-text-color);
        text-decoration: none;
    }
}

.navigation__dropdown--right {
    right: 0;
}

.navigation__item--right {
    margin-left: auto;
}

.navigation__label {
    align-self: center;
    font-family: $slab;
    font-weight: $bold;

    .icon {
        color: $secondary;
    }

    &:after {
        content: "";
        margin-left: 0.75rem;
    }
}

.navigation__item:hover>.navigation__dropdown,
.navigation__dropdown:hover {
    visibility: visible;
    opacity: 1;
}

// IE & Edge don't currently support this.
.navigation__item:focus-within>.navigation__dropdown {
    visibility: visible;
    opacity: 1;
}

.priority {
    white-space: nowrap;
}

.priority-menu {
    display: flex;
    flex-flow: row nowrap;
    position: relative;

    [data-hidden="true"] {
        display: none;
    }

    [data-hidden="false"] {
        display: flex;
    }
}

.overflow {
    background: var(--bg-color);
    display: flex;
    padding: 0;
    width: calc(100vw - 0.5rem);
    margin: 0;
    text-align: unset;

    &[data-hidden="true"] {
        display: none;
    }

    &[data-hidden="false"] {
        display: block;
    }

    .navigation__item {
        a {
            padding: 0.5rem;
        }
    }
}

.overflow-trigger {
    @include plain-button();
    align-self: stretch;
    margin-right: 0.5rem;
    display: inline-block;

    &:focus {
        outline: none;
    }

    color: $text;
    cursor: pointer;
}

.overflow-trigger--light {
    color: white;
}

.navigation--footer {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    margin-top: 0.25rem;
    margin-bottom: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 0.8rem;
    white-space: nowrap;
}

.footer-menu-container {
    display: flex;

    .footer-menu {
        @include media("<=small") {
            margin-bottom: 1rem;
            flex-basis: 50%;
        }
    }

    .footer-menu--first {
        @include media("<=small") {
            flex-basis: 100%;
        }

        .plain-list--links {
            column-count: 2;
            column-gap: 2rem;
        }
    }

    @include media(">small") {
        justify-content: space-between;
        padding-right: 3rem;
        box-sizing: border-box;
    }

    @include media("<=small") {
        flex-wrap: wrap;
    }
}

.footer__site-select {
    margin: 0.5rem 0 0 0;
    display: flex;
    padding: 0.5rem 0;
    border-top: 1px solid white;

    >span {
        margin-right: 0.75rem;
    }
}