.carousel {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.carousel__trigger {
    font-family: $slab;
    box-sizing: border-box;
    flex: 1;
    display: block;
    padding: 0.5rem;
    background: $paleGray;
    transition: all 0.2s linear;

    &:last-of-type {
        margin-left: auto;
    }

    &.active {
        background: $secondary;

        &:link,
        &:visited,
        &:active {
            color: white;

            &:hover {
                color: white;
            }
        }
    }

    @include media("<xsmall") {
        display: none;
    }
}

.carousel__item {
    position: relative;
    display: none;

    &.active {
        display: block;
    }
}

.carousel__caption {
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(255,255,255,0.85);
    max-width: 75%;
}

.carousel__link {
    font-family: $slab;
    display: block;
    padding: 0.5rem 0.75rem;
    font-size: 2rem;

    @include media ("<xsmall") {
        font-size: 1.25rem;
    }
}
