/*
    Note: this component uses `<elem class="block block--mod">...</elem>` syntax.
*/

.section {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    clear: both;
}

.section__actions {
    text-align: right;
    margin-top: 0.5rem;
}

// TODO: Deprecate this, replace with section__intro
.section--intro {
    font-size: 1.25rem;
}

.section__intro {
    font-size: 1.25rem;
    margin-bottom: 1rem;

    .section__intro-title {
        font-size: 1.75rem;
        font-family: $slab;
        font-weight: $normal;
    }

    .section__intro-subtitle {
        margin-top: 0;
        font-weight: $light;
    }
}

.section__body {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.section--nav {
    padding: 0;
}

.section--small-center {
    @include media("<medium") {
        text-align: center;
    }
}

.section--dark {
    background-color: $primary;
    color: white;

    a {
        color: $lightBlue;
    }
}

.section--footer {
    @extend .section--dark;
}

.section--light {
    background-color: $gray;
    color: white;
}

.section--red {
    border-top: 5px solid $secondary;
}

.section--lighter {
    background-color: $lightGray;
    color: $text;
}

.ad-unit + .section--primary {
    padding-top: 1rem;
}

.section--primary {
    padding-top: 0;
    & > :first-child {
        padding-top: 0;
        margin-bottom: 1rem;
    }
}

.section--secondary {
    border-top: 1px solid $gray;
    margin-bottom: 0.5rem;
}

.section--constrain {
    max-width: 900px;
    margin: auto;
}

.section__title {
    font-size: 1.5rem;
    font-family: $slab;
    font-weight: $normal;
}

.section--collapse {
    padding: 0;
}
