.sf-features {
    box-sizing: border-box;
    padding: 0;
    background: $paleGray;
    margin-bottom: 1rem;
    height: 100%;
}

.sf-features__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 0.5rem;
    background: $gray;

    h1 {
        font-family: $slab;
        text-align: center;
        font-size: 1.25rem;
    }
}

.sf-features__list-container {
    padding: 0.5rem;
}

.sf-features__list-item {
    padding: 0.5rem 0.25rem;

    .icon {
        color: $secondary;
        margin-right: 0.5rem;
    }
}

.sf-features__list-title {
    padding: 0.5rem 0.25rem;
    font-weight: $bold;
    font-family: $slab;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 0;
}