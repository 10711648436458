.tippy {
    &:focus {
        outline: none;
    }
    cursor: pointer;
}

.tippy-popper {
    z-index: 999999;
    &:focus {
        outline: none;
    }
}

.tippyHtml {
    display: none;
}

.tippy-box[data-theme~='dcd'] {
    padding: 0.25rem;
    background-color: $lightGray;
    color: inherit;

    &[data-placement^='top'] > .tippy-arrow {
        bottom: 0;
    
        &::before {
          bottom: -7px;
          left: 0;
          border-width: 8px 8px 0;
          border-top-color: initial;
          transform-origin: center top;
        }
      }
    
      &[data-placement^='bottom'] > .tippy-arrow {
        top: 0;
    
        &::before {
          top: -7px;
          left: 0;
          border-width: 0 8px 8px;
          border-bottom-color: initial;
          transform-origin: center bottom;
        }
      }
    
      &[data-placement^='left'] > .tippy-arrow {
        right: 0;
    
        &::before {
          border-width: 8px 0 8px 8px;
          border-left-color: initial;
          right: -7px;
          transform-origin: center left;
        }
      }
    
      &[data-placement^='right'] > .tippy-arrow {
        left: 0;
    
        &::before {
          left: -7px;
          border-width: 8px 8px 8px 0;
          border-right-color: initial;
          transform-origin: center right;
        }
      }

    .tippy-arrow {
        & {
            width: 15px;
            height: 15px;
            color: $lightGray;
          }

          &::before {
            content: '';
            position: absolute;
            border-color: transparent;
            border-style: solid;
          }
    }

    .at-share-btn {
        display: block;
    }
}
