.podcast-player {
    position: sticky;
    top: 0;
    z-index: 99;
    background: $paleGray;
}

.podcast-player__playlist {
    background: $paleGray;
    padding: 1rem 2rem;
}

.podcast-player__episode {
    padding: 0.5rem 1rem;
    background: white;
}