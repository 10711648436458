.sf-image-panel__image-container {
    max-width: 440px;
}

.sf-image-panel__image-container--left {
    @include media (">small") {
        float: left;
        margin-right: 0.5rem;
    }
}

.sf-image-panel__image-container--right {
    @include media (">small") {
        float: right;
        margin-left: 0.5rem;
    }
}

.sf-image-panel__image__caption {
    font-family: $slab;
}