.ceeda-header {
    background-color: $ceedaLight;
    color: white;
    margin-bottom: 1rem;
}

.ceeda-header__logo {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.ceeda-header__heading {
    padding: 1rem;
}

.ceeda-header__title {
    font-family: $slab;
    font-weight: $normal;
    font-size: 2.5rem;
    color: $ceeda;
}