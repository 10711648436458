.sf-venue {
    position: relative;
}

.sf-venue__map {
    height: 350px;
    margin-bottom: 0.5rem;
}

.sf-venue__overlay {
    position: absolute;
    top: 1rem;
    left: 1rem;
    max-height: 60%;
    max-width: 30%;
    background: $secondary;
    color: white;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    .venue__title {
        margin-bottom: 0;
    }
}

.venue__title {
    font-size: 1.5rem;

    &+.venue__link {
        margin-top: 1.5rem;
    }
}
