.a2a {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;

    .a2a_button {
        height: 32px;
        line-height: 32px;
        width: 32px;
        position: relative;
        text-align: center;
        text-indent: 20px;
        font-size: 80%;
        transition: all 0.2s linear;

        &:not(:last-of-type) {
            margin-right: 0.5rem;
        }

        @include media("<=medium") {
            flex-grow: 1;
            padding: 0.25rem;

            .a2a_svg {
                width: 100%;

                svg {
                    left: 0;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    .a2a_dd {
        width: 32px;
        padding: 0;
        background: $gray;

        @include media("<=medium") {
            flex-grow: 0;
            padding: 0.25rem;
        }
    }

    .a2a_button_facebook {
        background: rgb(59, 89, 152);
        &:hover {
            background: lighten(rgb(59, 89, 152), 5%);
        }
    }

    .a2a_button_twitter {
        background: rgb(85, 172, 238);
        &:hover {
            background: lighten(rgb(85, 172, 238), 5%);
        }
    }

    .a2a_button_linkedin {
        background: rgb(0, 123, 181);
        &:hover {
            background: lighten(rgb(0, 123, 181), 5%);
        }
    }

    .a2a_button_reddit {
        background: rgb(255,69,0);
        &:hover {
            background: lighten(rgb(255,69,0), 5%);
        }
    }

    .a2a_button_google_plus {
        background: rgb(221, 75, 57);
        &:hover {
            background: lighten(rgb(221, 75, 57), 5%);
        }
    }

    .a2a_button_email {
        background: rgb(1, 102, 255);
        &:hover {
            background: lighten(rgb(1, 102, 255), 5%);
        }
    }

    .a2a_svg {
        background: none !important;

        svg {
            width: 20px;
            height: 20px;
            top: 6px;
            left: 6px;
            margin: 0;
        }
    }

}

// overrides

.a2a_default_style a:hover .a2a_svg,
.a2a_floating_style a:hover .a2a_svg,
.a2a_overlay_style a:hover .a2a_svg svg {
    opacity: 1 !important;
}

.a2a_menu,
.a2a_menu_find_container,
.a2a_menu .a2a_svg
{
    border-radius: 0 !important;
}
