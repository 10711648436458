@mixin inline-list($spacing: 0.5rem) {
    list-style-type: none;
    margin: 0;
    padding-left: 0;
    > li {
        display: inline;
        &:not(:last-of-type) {
            margin-right: $spacing;
        }
    }
}

@mixin no-bullets() {
    list-style: none;
    padding-left: 0;

    > li {
        display: block;
    }
}

@mixin square-bullets() {
    list-style-type: square;
    list-style-position: inside;
    padding-left: 0;

    > li {
        display: block;
    }
}

@mixin plain-button() {
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
}

@mixin scrollable() {
    white-space: nowrap;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    &::-webkit-scrollbar { 
        display: none; 
    }
}
