.sf-partner {
    margin-bottom: 1rem;
}

.sf-partner__title {
    font-size: 1rem;
    text-align: center;
}

.sf-partner__logo {
    border: 1px solid $lightGray;
    display: block;
    padding: 0.75rem 1rem;
    margin: 0 auto;
    box-sizing: border-box;
    transition: border-color 0.2s linear;
    text-align: center;

    &:link,
    &:visited,
    &:active {
        &:hover {
            border-color: $gray;
        }
    }
}

.sf-partner__logo--white {
    background: white;
    border: none;
}
