/* HEADER
   ----------
   Common header for the site.
   Displays the logo, the login widget and the search widget.

*/
.header {
  display: flex;
  margin: auto;
}

.header__nav-bar {
  display: flex;
  align-items: center;

  .header__primary-menu,
  .header__primary-menu>li {
    height: 100%;
  }

  @include media("<=small") {
    display: none;
  }
}

.header__primary-menu {
  flex: 1;
}

.header__logo {
  padding: 0.5rem 0;
  font-size: 1rem;
  margin: 0 1rem 0 0;
  text-align: center;

  img {
    vertical-align: middle;
    width: 75px;

    @include media("<medium") {
      max-width: inherit;
    }
  }
}

.header__tools {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: auto;
}

.header__user-menu {
  margin-right: 0.5rem;

  &,
  &>li {
    height: 100%;
  }
}

.header__menu-toggle {
  /* 
        Button to toggle state of mobile menu
  */
  background: transparent;
  border: 0;
  display: block;
  cursor: pointer;
  position: relative;
  width: 40px;
  height: 40px;
  margin: auto 0.25rem auto auto;
  z-index: 100;
  top: -2px;
  outline: 0;
  padding: 0;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  height: 100%;

  @include media(">small") {
    display: none;
  }

  >.header__menu-toggle-icon {
    height: 12px;
    position: relative;
    width: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  &.is-active {
    span:nth-child(1) {
      opacity: 0;
    }

    span:nth-child(2) {
      top: 5px;
      transform: rotate(45deg);
    }

    span:nth-child(3) {
      opacity: 0;
    }

    span:nth-child(4) {
      top: 5px;
      transform: rotate(-45deg);
      opacity: 1;
    }
  }

  span {
    position: absolute;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background-color: #fff;
    transition: all 0.2s ease-in-out;
    transform-origin: center;
  }

  span:nth-child(1) {
    top: 0;
  }

  span:nth-child(2) {
    top: 50%;
  }

  span:nth-child(3) {
    top: 100%;
  }

  span:nth-child(4) {
    top: 50%;
    opacity: 0;
  }
}