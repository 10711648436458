.sf-statistic {
    text-align: center;
}

.sf-statistic__image {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.sf-statistic__figure {
    display: block;
    font-size: 3rem;
    font-family: $slab;
    color: $secondary;
}

.sf-statistic__text {
    display: block;
    font-size: 1.25rem;
}
