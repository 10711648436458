/* Navigation Mobile
-------------------------
Slide in mobile menus
*/

.navigation-mobile {
    position: fixed;
    top: 55px;
    height: 100%;
    background-color: $primary;
    width: 100%;
    z-index: 1200;
    transition: transform 0.2s ease-in-out;
    transform: translateX(-100%);

    &.is-active {
        transform: translateX(0);
    }
}

.navigation-mobile__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;

    > ul {
        position: relative;
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    > ul > li ul {
        position: absolute;
        top: 0;
        background-color: $primary;
        height: 100%;
        width: 100%;
        list-style: none;
        margin: 0;
        padding: 0;
        transform: translateX(100%);
        transition: transform 0.2s ease-in-out;
        z-index: 100;

        &.is-active {
            transform: translateX(0%);
        }
    }
}

.navigation-mobile__item {
    > a,
    > button {
        border: 0;
        background: transparent;
        text-decoration: none;
        padding: 15px 50px 15px 40px;
        color: white !important;
        font-size: 1.2rem;
        display: block;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        text-align: left;

        > svg {
            position: absolute;
            right: 40px;
            top: 25px;
        }
    }

    &--dropdown,
    &--up {
        @extend .navigation-mobile__item;
        > a:after,
        > button:after {
            color: white;
            position: absolute;
            margin: auto;
            border-style: solid;
            border-width: 0.15em 0.15em 0 0;
            content: "";
            height: 0.6em;
            vertical-align: top;
            width: 0.6em;
            transform: rotate(45deg);
            right: 20px;
            top: 0;
            bottom: 0;
            transition: all 0.2s ease-in-out;
        }
        &.is-unavailable a {
            color: #ccc;
        }
    }
    &--up {
        > a,
        > button {
            padding: 20px 50px 20px 80px;
            border-bottom: 1px solid white;
            margin-bottom: 5px;
        }
        > a:after,
        > button:after {
            transform: rotate(-135deg);
            left: 40px;
            right: auto;
        }
    }
}

.navigation-mobile__item--group {
    > span {
        font-weight: bold;
        padding: 15px 50px 15px 40px;
        color: white !important;
        font-size: 1.2rem;
        display: block;
        border-bottom: 1px solid white;
    }
}

.navigation-mobile__item--group-item {
    > a,
    > button {
        font-weight: normal;
        padding-left: 60px;
    }
}

.is-mobile-menu-active {
    /* 
        State modifier applied to the document body when the menu is opened.
        Used to prevent scrolling of the page.
    */

    overflow: hidden;
}

.is-mobile-menu-active {
  /* 
        State modifier applied to the document body when the menu is opened.
        Used to prevent scrolling of the page.
    */

  overflow: hidden;
}
