.agenda__timeslot {
    margin-bottom: 1rem;
}

.agenda__session {
    padding: 0.5rem;
    border: 1px solid $paleGray;
    margin-bottom: 0.5rem;
}

.agenda__session-title {
    font-size: 1rem;
    font-weight: $bold;
}

.agenda__timeslot-header {
    border-bottom: 1px solid $secondary;
}

.agenda__session-body {
    font-size: 0.9rem;
}

.agenda__session-meta {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.agenda__session-speaker {
    border-radius: 50%;
}

.agenda__session-cta {
    &:only-child {
        margin-left: auto;
    }
}
