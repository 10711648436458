.avatar {
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
    text-align: center;
}

.avatar__link {
    font-size: 110%;
    display: block;
    margin: 0.25rem 0;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}

.avatar__info {
    display: block;
    font-size: 90%;
}

.avatar--rounded {
    border-radius: 20px;
}
