@keyframes lum-fade {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes lum-breathe {
    0% { opacity: 0.75; }
    100% { opacity: 0.25; }
}

@keyframes lum-fadeZoom {
    0% { transform: scale(0.5); opacity: 0; }
    100% { transform: scale(1); opacity: 1; }
}

.lightbox-trigger {
    position: absolute;
    background: rgba(0, 0, 0, 0.75);
    top: 0;
    right: 0;
    padding: 0.5rem;
}

.lum-lightbox {
    z-index: 999999;
    background: lighten(black, 10%);
    position: fixed;
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.lum-lightbox.lum-open {
    display: block;
}

.lum-lightbox-inner {
    display: flex;
    height: 100%;
    overflow: hidden;
}

.lum-lightbox-controls {
    color: white;
    padding: 3rem 0.5rem 0.5rem 0.5rem;
    background: black;
    text-align: center;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 75px;
    box-sizing: border-box;
}

.lum-lightbox-index {
    font-size: 80%;
    margin-bottom: 1rem;
}

.lum-button {
    display: inline-block;
    width: 100%;
    color: darken(white, 30%);
    cursor: pointer;
    background: none;
    appearance: none;
    border: none;
    transition: color 0.2s linear;

    &:focus {
        outline: none;
    }

    &:hover {
        color: white;
    }
}

.lum-next-button {
    margin-bottom: 1rem;
}

.lum-lightbox-close {
    margin-bottom: 2rem;
}

.lum-lightbox-inner img {
    position: relative;
    max-width: 100%;
    max-height: 100%;
}

.lum-lightbox-caption:not(:empty) {
    padding: 3rem 1rem 1rem 1rem;
    background: black;
    color: #fff;
    min-width: 15rem;
    width: 30vw;
    box-sizing: border-box;

    a:link, a:active, a:visited {
        color: white;
        box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.6);
        transition: box-shadow 0.2s linear;
    }

    a:hover {
        box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 1);
    }
}

.lum-lightbox-image-wrapper {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid $primary;
}

.lum-loading .lum-lightbox-loader {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../img/logo-2x.png');
    background-size: 75px 40px;
    background-repeat: no-repeat;
    background-position: center;
    animation: lum-breathe 750ms infinite linear;
}

.lum-lightbox.lum-opening {
    animation: lum-fade 180ms ease-out;
}

.lum-lightbox.lum-opening .lum-lightbox-inner {
    animation: lum-fadeZoom 180ms ease-out;
}

.lum-lightbox.lum-closing {
    animation: lum-fade 300ms ease-in;
    animation-direction: reverse;
}

.lum-lightbox.lum-closing .lum-lightbox-inner {
    animation: lum-fadeZoom 300ms ease-in;
    animation-direction: reverse;
}

.lum-img {
    transition: opacity 120ms ease-out;
}

.lum-loading .lum-img {
    opacity: 0;
}

@include media("<small") {
    .lum-lightbox-inner {
        flex-direction: column;
    }

    .lum-lightbox-image-wrapper {
        padding: 0;
        border: none;
    }

    .lum-lightbox-caption:not(:empty){
        width: 100%;
        padding: 1rem;
    }

    .lum-button {
        width: auto;
        padding: 0.5rem;
    }

    .lum-lightbox-close,
    .lum-next-button,
    .lum-previous-button,
    .lum-lightbox-index {
        margin: 0;
    }

    .lum-previous-button {
        order: 1;
    }

    .lum-lightbox-index {
        display: inline-block;
        order: 2;
        min-width: 50px;
    }

    .lum-next-button {
        order: 3;
    }

    .lum-lightbox-close {
        order: 4;
        position: absolute;
        right: 0.5rem;
    }

    .lum-lightbox-controls {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
        order: -1;
        position: relative;
    }
}

@keyframes lum-noop {
    0% { zoom: 1; }
}

.lum-lightbox.lum-opening, .lum-lightbox.lum-closing {
    animation: lum-noop 1ms;
}

.lum-lightbox-loader {
    display: none;
}
