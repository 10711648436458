/*
    Note: this component uses `<elem class="block block--mod">...</elem>` syntax.
*/

.button {
    font-weight: $normal;
    line-height: 100%;
    border: none;
    display: inline-block;
    background-color: $secondary;
    cursor: pointer;
    padding: 0.5rem 0.75rem;
    color: white;
    text-align: center;
    box-sizing: border-box;
    position: relative;
    white-space: nowrap;

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
        opacity: 0;
        transition: opacity 0.2s linear;
    }

    &:hover {
        &:before {
            opacity: 1;
        }
    }

    &:disabled {
        background-color: $gray;
        cursor: default;
    }

    @extend .nav-link--light;
}

.button--disabled {
    background-color: $gray;
}

.button--block {
    display: block;
    width: 100%;
    white-space: normal;
}

.button--block-narrow {
    display: block;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    white-space: unset;
    line-height: 1.25;
}

.button--block-centered {
    max-width: 35vw;
    margin-left: auto;
    margin-right: auto;
    white-space: unset;
    line-height: 1.25;
}

.button--cta {
    font-weight: $bold;
    font-size: 1.25rem;
    padding: 0.75rem 1rem;
    border-width: 2px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 1);
    transition: border-color 0.2s linear;

    &.button--cta-primary {
        &:not(.button--block) {
            padding-left: 3.5rem;
        }

        background-image: url('../img/button--check.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-origin: border-box;
    }

    &:hover {
        border-color: rgba(0, 0, 0, 0.25);
    }
}

.plain-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}

.ghost-button {
    font-weight: $bold;
    color: $secondary;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    box-sizing: border-box;
    text-align: center;
    white-space: nowrap;

    &:link,
    &:active,
    &:visited {
        text-decoration: none;
        color: $secondary;
    }

    &:hover {
        background: $secondary;
        color: white;
        transition: color 0.2s linear, background 0.2s linear;
    }

    background: none;
    border: 2px solid $secondary;
}

.ghost-button--inverse {
    color: white;

    &:link,
    &:active,
    &:visited {
        color: white;
    }

    &:hover {
        background: white;
        color: $secondary;
    }

    border: 2px solid white;
}