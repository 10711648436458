/*
    Note: this component uses `<elem class="block block--mod">...</elem>` syntax.
*/

.ad-unit {
    > div {
        max-width: 100%;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    margin-bottom: 1rem;
    transform-origin: 0 50%;
    overflow: hidden;
}

.ad-unit--mpu-1,
.ad-unit--mpu-2,
.ad-unit--mpu-3{
    text-align: center;
}

.ad-unit--leaderboard {
    margin-bottom: 0.5rem;
}

.ad-unit--leaderboard,
.ad-unit--leaderboard-footer {
    @include media("<=xsmall") {
        iframe {
            transform: scale(0.5);
        }
    }

    @include media(">small", "<=medium") {
        iframe {
            transform: scale(0.8);
        }
    }

    @include media(">xsmall", "<=small") {
        iframe {
            transform: scale(0.7);
        }
    }
}
