.image-container {
    position: relative;
    line-height: 0;
}

.image__source {
    font-size: 80%;
    display: block;
    position: absolute;
    line-height: 1rem;
    bottom: 0;
    left: 0;
    color: white;
    background: rgba(0,0,0,0.5);
    padding: 0.5rem;
}
