.accordion__item {
    position: relative;

    &:not(:last-child) {
        border-bottom: 1px solid $gray;
    }

    [data-hidden="true"] {
        display: none;
    }

    [data-hidden="false"] {
        display: block;
    }
}

.accordion__item__title {
    font-family: $slab;
    font-weight: $normal;
    font-size: 1.25rem;
    display: block;
    padding: 0.75rem 0;
    margin: 0.75rem 0;
    cursor: pointer;
    color: $primary;
    transition: color 0.2s linear;

    &:hover {
        color: $text;
    }

    .accordion__item__indicator {
        transition: 0.2s all linear;
    }

    &.is-active {
        .accordion__item__indicator {
            transform: rotate(-180deg);
        }
    }
}

.accordion__item__body {
    margin-inline-start: 0;
}