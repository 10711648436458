.dcpro-header {
    background-color: $dcpro;
    color: white;
    margin-bottom: 1rem;
}

.dcpro-header__header {
    padding: 1rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid white;

    .dcpro-header__heading {
        font-family: $slab;
        font-weight: $normal;
        font-size: 2.5rem;
    }

    .dcpro-header__title {
        flex-grow: 1;

        .dcpro-header__logo {
            margin-top: 0.5rem;
        }
    }

    .dcpro-header__title-info {
        display: block;
        margin-left: 1.5rem;
    }
}

.dcpro-header__header--compact {
    padding: 2rem 1rem;

    .dcpro-header__title {
        .dcpro-header__logo {
            display: block;
            margin-top: 0;
            margin-right: 1rem;
            margin-bottom: 1rem;
        }

        .dcpro-header__heading {
            margin-bottom: 0;
        }
    }
}

.dcpro-header__body {
    padding: 1rem;
}

.dcpro-header__footer {
    background: $primary;
    text-align: right;
    padding: 0.75rem 1rem;
}
