.pagination {
    padding: 1rem 0.5rem;
    border-top: 1px solid $secondary;
}

.pagination__prev {
    text-align: left;
    lost-column: 1/3 flex;
}

.pagination__info {
    text-align: center;
    lost-column: 1/3 flex;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.pagination__next {
    text-align: right;
    lost-column: 1/3 flex;
}
