// single-field inline form
.form--single {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
    min-width: 300px;

    .form__input {
        flex: 1;
    }

    .form__label {
        align-self: center;
        margin: 0 0.5rem;
    }
}

.content-form {
    .form__input {
        background-color: white;
    }
}

.flex-form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .form__field {
        flex-basis: 100%;
    }

    .form__field--half {
        flex-basis: 49%;
    }
}

.form--inline {
    display: inline;
}

.form__field {
    &:not(last-of-type) {
        margin-bottom: 1rem;
    }
}


.form__fieldset {
    padding: 0;
    border: none;
    legend {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
    }

    &:not(:last-of-type) {
        margin-bottom: 2rem;
    }
}

.form__label {
    font-weight: $bold;
    @include media("<xsmall"){
        display: block;
    }
    p {
        display: inline;
    }
}

.form__help-text {
    font-weight: $normal;
    display: block;
    font-size: 0.8rem;
    margin-top: 0.25rem;
}

.form__input {
    box-sizing: border-box;
    border: 1px solid $gray;
    background: $paleGray;
    transition: border-color 0.2s linear;
    padding: 0.5rem 0.75rem;
    width: 100%;
    font-weight: $normal;
    border-radius: 0;
    appearance: none;

    &:focus {
        outline: none;
        border-color: $secondary;
    }
}

.form__input--light {
    @extend .form__input;
    background: white;
}

.form__input--error {
    @extend .form__input;
    border-color: $error;
}

.form__select {
    @extend .form__input;
    &::-ms-expand {
        display:none;
    }
    border-radius: 0;
    background-image: url('../img/triangle-down.svg');
    background-repeat: no-repeat;
    background-position-x: calc(100% - 10px);
    background-position-y: center;
    padding-right: 20px;
}

.form__actions {
    text-align: right;
}

.form__checkbox {
    position: absolute;
    opacity: 0;

    & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
    }

    & + label:before {
        content: '';
        box-sizing: border-box;
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 1rem;
        height: 1rem;
        border: 1px solid $gray;
        background: $paleGray;
        transition: all 0.2s linear;
    }

    &:hover + label:before {
        border: 1px solid $secondary;
    }

    &:focus + label:before {
        outline: none;
    }

    &:checked + label:before {
        border-color: $secondary--light;
        background: $secondary--light;
    }

    &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
    }

    &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
    }

    &:checked + label:after {
        content: '';
        position: absolute;
        left: 3px;
        top: 7px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow: 
            2px 0 0 white,
            4px 0 0 white,
            4px -2px 0 white,
            4px -4px 0 white,
            4px -6px 0 white,
            4px -8px 0 white;
        transform: rotate(45deg);
    }

}

.errorlist {
    @extend .plain-list;
    li {
        font-size: 0.8rem;
        color: $error;
    }
}
