.filter__list {
    .filter__list-item {
        width: 100%;
        background: none;
        border: none;
        cursor: pointer;
        text-align: left;
        padding: 0.5rem;
        margin-bottom: 0.25rem;
        transition: all 0.2s linear;

        &:hover {
            background: $secondary;
            color: white;
        }

        &:focus {
            outline: none;
        }

        &.is-active {
            background: $secondary;
            color: white;
        }
    }
}

.js-filter-item {
    &.js-hidden {
        display: none;
    }
}
