.sf-pullquote {
    border-top: 4px solid $primary;
    position: relative;
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    font-family: $sans-serif;
    font-weight: $extrabold;
    font-size: 1.5rem;
    box-sizing: border-box;
    margin-bottom: 1rem;
    p {
        margin: 0;
    }
}

.sf-pullquote--left {
    @extend .sf-pullquote;
    @include media(">medium") {
        display: inline-block;
        float: left;
        margin-left: 0;
        margin-right: 1rem;
        width: 276px;
    }
}

.sf-pullquote--right {
    @extend .sf-pullquote;
    @include media(">medium") {
        display: inline-block;
        float: right;
        margin-left: 1rem;
        margin-right: 0;
        width: 276px;
    }
}

.sf-pullquote__attribution {
    margin-top: 0.5rem;
    font-family: $sans-serif;
    font-style: normal;
    font-size: 1rem;
    display: block;
}

.sf-pullquote--small {
    font-size: 1.25rem;
}
