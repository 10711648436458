.table {
    margin-bottom: 1rem;
    table-layout: auto;

    th, td {
        padding: 0.25rem 0.5rem;
    }
}

.table--block {
    width: 100%;
}

.table__heading-left {
    text-align: left;
    vertical-align: top;
    padding-right: 0.5rem;
}

.table--striped {
    border-collapse: collapse;
    tbody tr {
        &:nth-child(even) {
            background: $paleGray;
        }
    }
}

.table__heading {
    background: $paleGray;
    border-bottom: 1px solid $primary;
    line-height: 1.5rem;
}

.table--bordered {
    border: 1px solid $lightGray;
    thead {
        border: 1px solid $lightGray;
    }
}

.table--centered {
    .table__row {
        text-align: center;
    }
}
