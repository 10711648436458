/*
    Note: this component uses `<elem class="block block--mod">...</elem>` syntax.
*/

.line-block {
    position: relative;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
}

.line-block__image {
    display: block;
    max-width: 100%;
    height: auto;

    @include media("<xsmall") {
        display: none;
    }

    @include media(">=xsmall") {
        & + .line-block__body {
            margin-left: 0.5rem;
        }
    }
}

.line-block__image--top {
    align-self: flex-start;
    padding-top: 0.5rem;
}

.line-block__image--round {
    border-radius: 50%;
}

.line-block__image--portrait {
    flex-basis: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    position: relative;
    overflow: hidden;

    > img {
        z-index: 1;
    }

    .line-block__image-backdrop {
        display: block;
        background-color: white;
        background-size: cover;
        background-position-y: center;
        position: absolute;
        width: 100%;
        height: 100%;
        filter: blur(5px) opacity(50%) brightness(85%);
        transform: scale(1.2);
    }
}

.line-block__title {
    font-size: 1rem;
    margin: 0;
}

.line-block__body {
    padding: 0.5rem 0;
    flex: 1;
}

.line-block__meta {
    margin-top: 0.5rem;
    font-size: 0.8rem;
    width: 100%;

    a {
        position: relative;
        z-index: 2;
    }

    .svg-inline--fa {
        vertical-align: inherit;
    }
}

.line-block--compact {
    .line-block__body {
        padding: 0;
    }
}

.line-block--collapse {
    margin-bottom: unset;
}

.line-block--top {
    align-items: flex-start;
}
