html {
    font-size: 16px;
    color: $text;
}

.wf-loading {
    visibility: hidden;
}

body {
    font-weight: $normal;
    font-size: 1rem;
    line-height: 1.33;
    font-family: $sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: $bold;
    margin-top: 0;
    margin-bottom: 0.5rem;
}

b, strong {
    font-weight: $extrabold;
}

.text-italic {
    font-style: italic;
}

.page-heading {
    font-family: $slab;
    font-weight: $bold;
    font-size: 2.5rem;
}

.page-intro {
    font-family: $slab;
    font-weight: $light;
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 2.5rem;
    padding: 0.5rem 0;
    position: relative;

    &:after {
        position: absolute;
        display: block;
        content: "";
        height: 3px;
        width: 6rem;
        background: $secondary;
        bottom: 0;
        left: 0;
    }
}

.article-heading {
    font-family: $slab;
    font-size: 2.5rem;
    line-height: 115%;
    font-weight: $bold;
    margin-bottom: 1rem;

    @include media ("<medium") {
        font-size: 2rem;
    }

    @include media("<xsmall") {
        font-size: 1.5rem;
    }
}

.article-byline {
    font-size: 0.8rem;
    
    > :not(:last-child) {
        margin-right: 0.5rem;
    }
}

.article-body {
    margin-top: 0.5rem;
    padding-top: 1rem;

    .block-text {
        line-height: 150%;
    }

    .article-sub-heading{
        p:first-child {
            margin-top: 1rem;
        }
    }
}

.rich-text {
    h1,
    h2,
    h3,
    h4,
    h5 {
        font-family: $slab;
    }
}

.article-sub-heading {
    font-weight: $light;
    font-size: 1.5rem;
    line-height: 125%;

    p:first-child {
        margin-top: 0;
    }
}

.sub-head {
    margin-top: -1.5rem;
    margin-bottom: 1rem;
}

.divider-label {
    display: block;
    text-align: center;
    overflow: hidden;
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-top: 1rem;
    margin-bottom: 1rem;

    &:before,
    &:after {
        background-color: $gray;
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 50%;
    }

    &:before {
        right: 0.5em;
        margin-left: -50%;
    }

    &:after {
        left: 0.5em;
        margin-right: -50%;
    }
}

.comment-count {
    img {
        vertical-align: bottom;
    }
}

a.link {
    color: $link;
}

a {
    font-weight: $bold;
    &:active,
    &:visited {
        color: $link;
        text-decoration: underline;
        text-decoration-skip-ink: auto;
    }
    &:visited {
        color: $visited;
    }
    &:hover {
        color: $navyBlue;
    }
}

button,
input,
optgroup,
select,
textarea {
    line-height: 1.33;
    font-family: $sans-serif;
}

.index-title {
    font-family: $slab;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $gray;

    .index-title__link {
        font-size: 1rem;
        padding: 0.5rem 0;

        .icon {
            color: $secondary;
        }
    }
}

.icon-prefix {
    .icon {
        color: $secondary;
        vertical-align: middle;
    }
}
