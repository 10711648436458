.tabs {
    @include inline-list(0);
    margin-bottom: 1rem;
    border-bottom: 1px solid $paleGray;
    display: flex;
    align-items: center;
}

.tabs--large {
    font-size: 1.25rem;
}

.tab {
    padding: 0.25rem 0.35rem;
    display: inline-block;
    &:link,
    &:active,
    &:visited {
        text-decoration: none;
        color: $gray;
        transition: all 0.2s linear;
        border-bottom: 2px solid rgba(0,0,0,0);

        &:hover {
            color: $secondary--light;
            border-bottom: 2px solid $secondary--light;
        }
    }

    &.is-active {
        color: $secondary;
        font-weight: $bold;
        border-bottom: 2px solid $secondary;
    }
}

.js-hidden-tab {
    display: none;
}

.js-active-tab {
    display: block;
}
