.panel {
    box-sizing: border-box;
    background: $paleGray;
    padding: 0.5rem;
    margin-bottom: 1rem;
}

.panel__title {
    font-family: $slab;
    font-size: 1.5rem;
}

.panel--factfile {
    padding: 1rem;
    border-top-width: 5px;
    border-top-style: solid;
    border-top-color: $text;
}

.panel--bordered {
    background: none;
    padding: 0.5rem 0;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
}

.panel--plain {
    background: none;
    padding: 0;
}

.panel__icon {
    display: block;
    margin: 0.5rem auto;
}

.panel__cta {
    margin-top: 0.5rem;
    text-align: right;
}
