.sf-award-categories {
    display: flex;
    flex-direction: row;
    justify-content: center;
    @include media(">small") {
        width: 80%;
        margin-left: auto;
        margin-right: auto;    
    }

    .sf-award__theme {
        flex-basis: 0;
        flex-grow: 1;
        margin: 1rem;
    }

    .sf-award__theme-title {
        font-family: $slab;
        font-size: 1.25rem;
    }
}

.category-list {
    li {
        position: relative;
        padding-left: 4.25rem;
        border: 1px solid $paleGray;
        

        &:before {
            content: attr(data-prefix);
            color: $gold;
            font-size: 2rem;
            font-family: $slab;
            position: absolute;
            top: 0.25rem;
            left: 0.25rem;
        }
    }
}
