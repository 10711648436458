.events {
    margin-bottom: 1rem;
}

.events__list-item {
    position: relative;
    transform-style: preserve-3d;
    &:not(:last-of-type) {
        margin-bottom: 0.5rem;
    }

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 12px 12px 12px 0;
    }

    &[data-continent="1"] {
        &:after{
            border-color: transparent #6c1a75 transparent transparent;
        }
    }
    &[data-continent="2"] {
        &:after{
            border-color: transparent #8fd03c transparent transparent;
        }
    }
    &[data-continent="3"] {
        &:after{
            border-color: transparent #3993ca transparent transparent;
        }
    }
    &[data-continent="4"] {
        &:after{
            border-color: transparent #c1201a transparent transparent;
        }
    }
}

.event__date {
    display: block;
    margin-left: 0.9rem;
}
