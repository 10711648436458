/*
    Note: this component uses `<elem class="block block--mod">...</elem>` syntax.
*/

.card {
    width: 100%;
    margin-bottom: 1rem;
    position: relative;
}

.card__image {
    display: block;
    position: relative;

    img {
        display: block;
        height: auto;
        width: 100%;
    }

    &:hover {
        .card__overlay--full {
            background: rgba(255, 255, 255, 0.6);
        }
    }
}

.card__overlay {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    max-width: 80%;
    padding: 0.25rem 0.5rem;
    background: rgba(255,255,255,0.9);
    transition: background 0.2s linear;

    > *:not(:last-child){
        margin-right: 0.25rem;
    }

    .card__title:not(:first-child) {
        border-left: 1px solid $lightGray;
        padding-left: 0.5rem;
        box-sizing: border-box;
    }
}

.card__overlay--full {
    width: 100%;
    max-width: 100%;
    height: 100%;
    justify-content: center;
    background: rgba(255,255,255,0.5);
    box-sizing: border-box;
}

.card__overlay-icon {
    font-size: 5rem;
    mix-blend-mode: multiply;
    color: $secondary;
}

.card__body {
    padding: 0.5rem 0;

    .rich-text p:first-child {
        margin-top: 0;
    }
}

.card__title {
    font-size: 1rem;
    font-weight: $bold;
    margin: 0;

    svg {
        color: $gray;
    }
}

.card__title + .card__image {
    margin-top: 0.5rem;
}

.card__intro {
    font-size: 0.9rem;
    margin-top: 0.5rem;

    p {
        margin: 0;
    }

    @include media("<xsmall") {
        display: none;
    }
}

.card__intro--large {
    font-size: 1.25rem;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}

.card__meta {
    margin-top: 0.5rem;
    font-size: 0.8rem;
    a {
        position: relative;
        z-index: 2;
    }
}

.card--large {
    .card__title {
        font-size: 1.5rem;
        font-family: $slab;
    }

    .card__overlay {
        font-size: 1rem;

        > *:not(:last-child){
            margin-right: 0.5rem;
        }
    }

    .card__image--portrait {
        @include media(">medium", "<=large") {
            > img {
                height: 220px;
            }
        }
    }

    .card__intro {
        @include media("<xsmall") {
            display: block;
        }
    }
}

.card__image--portrait {
    display: block;
    padding-bottom: 66%;
    position: relative;
    overflow: hidden;

    > img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        display: block;
        filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.25));
        width: auto;
        background: white;

        @include media(">medium", "<=large") {
            height: 120px;
        }

        @include media(">xsmall", "<=small") {
            height: 150px;
            .column--two-thirds & {
                height: 250px;
            }
            .column--third & {
                height: 120px;
            }
        }

        @include media("<=xsmall") {
            height: 150px;
            .column--xsmall-half & {
                height: 100px;
            }
        }
    }

    .card__image-backdrop {
        display: block;
        background-color: white;
        background-size: cover;
        background-position-y: center;
        position: absolute;
        width: 100%;
        height: 100%;
        filter: blur(5px) opacity(50%) brightness(85%);
        transform: scale(1.2);
    }
}

.card--centered {
    text-align: center;

    .card__image {
        img {
            width: initial;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.card--bordered {
    box-sizing: border-box;
    border: 1px solid $paleGray;

    .card__body {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
}

.card--flex {
    display: flex;
    flex-direction: column;

    .card__meta {
        margin-top: auto;
        padding-top: 0.5rem;
    }
}

.card--expanded {
    border-color: $lightGray;

    .card__meta {
        font-size: 1rem;
    }

    .card__cta {
        border-top: 1px solid $lightGray;
        margin-top: 0.5rem;
        align-items: center;
    }

    .card__footer {
        padding: 0.25rem 0.5rem;
        background: $paleGray;
    }
}

// related article stream block
.block-related_article {
    .card {
        @include media(">xsmall") {
            float: left;
            width: 276px;
            margin-left: 0;
            margin-right: 1.5rem;
            margin-bottom: 0;
        }

        border-bottom: 1px solid $gray;
    }
}
