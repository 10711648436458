// Wagtail-generated classes

.richtext-image {
    max-width: 100%;
    height: auto;

    &.right {
        float: right;
        margin-left: 0.5rem;
    }

    &.left {
        float: left;
        margin-right: 0.5rem;
    }

    &.full-width {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.block-table {
    td, th {
        vertical-align: top;
        padding: 10px 10px 10px 0;
        text-align: left;
    }
}

.responsive-object {
    position: relative;
}
.responsive-object iframe,
.responsive-object object,
.responsive-object embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
