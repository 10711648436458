.block-image {
    display: block;
    text-align: center;
    clear: both;
}

.sf-image {
    position: relative;

    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        @include media("<xsmall") {
            width: 100%;
            height: auto;
        }
    }
}

.sf-image--full {
    @extend .sf-image;
    @include media(">xsmall") {
        display: inline-block;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

.sf-image--left {
    @extend .sf-image;
    @include media(">xsmall") {
        display: inline-block;
        float: left;
        max-width: 358px;
        margin-left: 0;
        margin-right: 1rem;
    }
    @include media(">xsmall", "<=small") {
        max-width: 40vw;
    }
}

.sf-image--right {
    @extend .sf-image;
    @include media(">xsmall") {
        display: inline-block;
        float: right;
        max-width: 358px;
        margin-left: 1rem;
        margin-right: 0;
    }
    @include media(">xsmall", "<=small") {
        max-width: 40vw;
    }
}

.sf-image__meta {
    padding: 0.5rem;
    background: $paleGray;
    border-bottom: 1px solid $gray;
    text-align: left;
}

.sf-image__caption {
    display: block;
    font-weight: 500;
}

.sf-image__caption:not(:last-child) {
    margin-bottom: 0.25rem;
}

.sf-image__source {
    font-size: 80%;
    display: block;
}