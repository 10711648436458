body {
    background: white;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.container {
    width: 100%;
    max-width: 1200px;
    align-self: center;
    box-sizing: border-box;

    @include media("<medium") {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    @include media(">xsmall", "<=medium") {
        max-width: 880px;
    }

    @include media(">medium", "<large") {
        max-width: 1044px;
    }
}

.container--narrow {
    max-width: 1044px;
}

.container--center {
    margin-left: auto;
    margin-right: auto;
}

.flex-container {
    lost-flex-container: row;
    clear: both;
}

.flex-container--centered {
    justify-content: center;
}

.flex-container--bottom {
    align-items: flex-end;
}

.row {
    width: 100%;
    lost-utility: clearfix;
}

.column {
    width: auto;

    @include media(">xsmall") {
        margin-right: 20px;
    }
}

.column--flex {
    display: flex;
}

.column--full {
    width: 100%;

    @include media(">xsmall") {
        lost-column: 1 flex;
    }
}

.column--half {
    width: 100%;

    @include media(">xsmall") {
        lost-column: 1/2 flex;
    }
}

.column--quarter {
    width: 100%;

    @include media(">xsmall") {
        lost-column: 1/4 flex;
    }
}

.column--three-quarters {
    width: 100%;

    @include media(">xsmall") {
        lost-column: 3/4 flex;
    }
}

.column--third {
    width: 100%;

    @include media(">xsmall") {
        lost-column: 1/3 flex;
    }
}

.column--two-thirds {
    width: 100%;

    @include media(">xsmall") {
        lost-column: 2/3 flex;
    }
}

.column--fifth {
    width: 100%;

    @include media(">xsmall") {
        lost-column: 1/5 flex;
    }
}

.column--three-fifths {
    width: 100%;

    @include media(">xsmall") {
        lost-column: 3/5 flex;
    }
}

.column--two-fifths {
    width: 100%;

    @include media(">xsmall") {
        lost-column: 2/5 flex;
    }
}

.column--eigth {
    width: 100%;

    @include media(">medium") {
        lost-column: 1/8 flex;
    }

    @include media(">xsmall", "<=medium") {
        lost-column: 1/4 flex;
    }
}

.column--medium-half {
    @include media(">small", "<=medium") {
        lost-column: 1/2 flex;
    }
}

.column--medium-full {
    @include media(">small", "<=medium") {
        lost-column: 1 flex;
    }
}

.column--small-half {
    @include media(">xsmall", "<=small") {
        lost-column: 1/2 flex;
    }
}

.column--small-full {
    @include media(">xsmall", "<=small") {
        lost-column: 1 flex;
    }
}

.column--xsmall-full {
    @include media(">xsmall", "<=small") {
        lost-column: 1 flex;
    }
}

.column--xsmall-half {
    @include media("<=xsmall") {
        lost-column: 1/2 flex;
    }
}

.layout__content {
    margin-bottom: 1rem;

    .section+.section {
        margin-top: 1.5rem;
    }
}

@include media(">medium") {
    .layout--two-col {
        display: flex;

        .layout__content {
            flex: 1;
            margin-right: 20px;
        }

        .layout__rhs {
            flex: 0 0 300px;

            .block-index {
                margin-bottom: 1rem;
            }
        }
    }
}

@include media(">xsmall", "<=medium") {
    .layout__rhs {
        display: flex;
        flex-wrap: wrap;
        padding-left: 1rem;
        padding-right: 1rem;

        >div {
            flex-basis: 50%;
        }
    }
}

@include media("<xsmall") {
    .layout__rhs {
        >div {
            margin-bottom: 1rem;
        }
    }
}

#main {
    lost-utility: clearfix;
    padding-top: 1rem;
    padding-bottom: 1rem;
    flex-grow: 1;

    &.main--condensed {
        padding-top: 0;

        .announcement {
            margin-bottom: 0;
        }
    }
}

#header,
#footer {
    display: flex;
    flex-direction: column;
}

#header {
    background-color: $primary;
}

.has-submenu {

    // account for event navigation
    .u-sticky {
        top: 135px;
    }
}

img {
    max-width: 100%;
    height: auto;
}

.sr-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.u-clear {
    clear: both;
    margin-bottom: 1rem;
}

.u-monochrome {
    filter: grayscale(100);
}

.u-paragraph-lead {

    // I don't like this either.
    p:first-child {
        margin-top: 0;
    }
}

.u-text-center {
    text-align: center;
}

.u-relative {
    position: relative;
}

.u-sticky {
    position: sticky;
    top: 50px;
}

.u-flex {
    display: flex;
    flex-direction: column;
}

.u-event {
    .brand-header {
        &:before {
            height: 190px;
            background: rgb(0, 0, 0);
            background: linear-gradient(180deg,
                    rgba(0, 0, 0, 1) 50%,
                    rgba(0, 212, 255, 0) 100%);
            pointer-events: none;
        }
    }

    &:before {
        position: fixed;
        top: 0;
        content: "";
        display: block;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        width: 100%;
        height: 658px;
        z-index: -1;
    }

    @include media(">=medium") {
        &:after {
            position: fixed;
            bottom: 0;
            content: "";
            display: block;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: bottom;
            width: 100%;
            height: 600px;
            z-index: -1;
        }
    }
}

.u-event-connect {
    background: #000103;

    &:before {
        background-image: url("../img/connectbg--top.jpg");
    }

    @include media(">=medium") {
        &:after {
            background-image: url("../img/connectbg--bottom.jpg");
        }
    }
}

.u-event-compute {
    background: #000103;

    &:before {
        background-image: url("../img/computebg--top.jpg");
    }

    @include media(">=medium") {
        &:after {
            background-image: url("../img/computebg--bottom.jpg");
        }
    }
}

.u-event-investment {
    background: #036588;

    &:before {
        background-image: url("../img/investmentbg--top.jpg");
    }

    @include media(">=medium") {
        &:after {
            background-image: url("../img/investmentbg--bottom.jpg");
        }
    }

    .brand-header {
        &:before {
            height: 190px;
            background: rgb(0, 0, 0);
            background: linear-gradient(180deg,
                    rgb(0, 73, 99) 50%,
                    rgba(0, 212, 255, 0) 100%);
            pointer-events: none;
        }
    }
}

.u-light-text {
    main {
        color: white;
    }

    .panel,
    .content-form {
        color: black;
    }

    .ticket__body>.ticket__price,
    .sf-statistic__figure,
    .filter__list-item {
        color: white;
    }

    .sf-partner__logo {
        background: white;
    }

    .section--secondary {
        &:first-of-type {
            border-top: none;
        }

        border-top-style: dashed;
    }

    .layout__content {
        .panel {

            .nav-link,
            .headline-link {
                color: $text;

                &:hover {
                    color: black;
                }
            }
        }

        .nav-link,
        .headline-link {
            color: white;

            &:hover {
                color: $paleGray;
            }
        }
    }

    .agenda__timeslot-header {
        border-bottom: none;
    }

    .agenda__session-partner {
        background: white;
        box-sizing: border-box;
        padding: 0.25rem 0.5rem;
    }

    .sf-announcement {
        color: white;
    }
}

.u-order-last {
    @include media(">small") {
        order: 99;

        &:nth-child(1n) {
            margin-right: 0;
            margin-left: 20px;
        }
    }
}

.u-inline {
    display: inline;
}

.u-anchor {
    padding-bottom: 18.5%;

    @include media("<=xsmall") {
        padding-bottom: 28%;
    }

    @include media(">xsmall", "<=small") {
        padding-bottom: 22%;
    }

    @include media(">small", "<=medium") {
        padding-bottom: 21%;
    }

    @include media(">medium", "<large") {
        padding-bottom: 14%;
    }
}

// visibility classes

.hide-for-small {
    @include media("<=small") {
        display: none !important;
    }
}

.show-for-small {
    @include media(">small") {
        display: none !important;
    }
}

.hide-for-medium {
    @include media("<=medium") {
        display: none !important;
    }
}

.show-for-medium {
    @include media(">medium") {
        display: none !important;
    }
}

.hide-for-large {
    @include media("<=large") {
        display: none !important;
    }
}

.show-for-large {
    @include media(">large") {
        display: none !important;
    }
}

.hide-labels {
    .label {
        display: none;
    }
}

.layout__mobile-menu {
    @include media(">small") {
        display: none;
    }
}

.js-display-for-anonymous {
    display: none;
}

.footer__contact-details {
    text-align: center;

    a:link,
    a:active,
    a:visited {
        color: white;
        text-decoration: none;
        font-weight: $bold;
    }

    a:hover {
        text-decoration: underline;
    }
}