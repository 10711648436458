.sf-gallery__item {
    background: $paleGray;
    margin-bottom: 1rem;
}

.sf-gallery__image {
    display: block;
}

.sf-gallery__intro {
    margin-top: 1rem;
    margin-bottom: 1rem;
    position: relative;
    color: white;
    background: black;
}

.sf-gallery__intro-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.sf-gallery__intro-inner {
    display: block;
    position: absolute;
    box-sizing: border-box;
    padding: 3rem 2rem;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    width: 100%;
}
