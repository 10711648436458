.js-slider {
    position: relative;
    overflow: hidden;
}

.js-slider-slide {
    position: relative;
}

.js-slider-hidden {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    animation-duration: 0.2s;
    animation-name: slideout;
}

.js-slider-active {
    display: block;
}

.js-slider-controls {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
    padding: 0.5rem;
}

.slider__button {
    @include plain-button();
    display: inline-block;
    &:focus {
        outline: none;
    }
    background: $primary;
    color: white;
    cursor: pointer;
    margin: 0 0.25rem;
    transition: background 0.2s linear, color 0.2s linear;
    &:hover {
        background: $paleGray;
        color: $primary;
    }
}

.js-slider-next-in {
    left: 0;
    animation-duration: 0.2s;
    animation-name: nextIn;
}

.js-slider-prev-in {
    right: 0;
    animation-duration: 0.2s;
    animation-name: prevIn;
}


@keyframes nextIn {
    from {
        left: 100%;
    }
    to {
        left: 0;
    }
}

@keyframes prevIn {
    from {
        right: 100%;
    }
    to {
        right: 0;
    }
}
